import React from "react"
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import ReactPlayer from 'react-player'
import * as Icon from 'react-feather'

import Layout from '../components/layout';

import logoImage from '../images/live/gowrite-live.png'
import diaryImage from '../images/live/matt-video-diary.jpg'
import booksImage from '../images/live/5-book-stack.png'

const LivePage=({ location }) => {

    return(
    <>
        <Helmet>
            <title>BoomWriter UK Live!</title>
            <meta name="description" content="A unique live writing and reading event featuring Matt Beighton" />
        </Helmet>

        <Layout location={location}>
            <div className="liveHero">
                <Container className="liveHero_layout">
                    <div className="liveHero_inner">
                        <img src={logoImage} className="logo" alt="BoomWriter UK Live!" />
                        <h1 className="hline"><span className="hline_quiet">featuring</span> <br/><span className="hline_loud">Matt Beighton</span></h1>
                        <p className="heading starts"><span className="headingSmall">coming soon</span> <br/>Summer 2021</p>
                    </div>
                </Container>
            </div>

            <Container className="liveSummarySection liveGrid">
                <div className="liveDetails liveGrid_main">
                    <h2>A spectacular writing experience for Key Stage Two writers!</h2>
                    <p>Join Matt Beighton, author of the Shadowland Chronicles & Monstacademy series for a special reading and writing event: BoomWrite UK Live!</p>
                    <p>This unique live experience will give your pupils the chance to help shape a branching adventure story with a children’s author that culminates in a published book for every child.</p>
                </div>
                <div className="liveCallout liveGrid_aside">
                    <h3>Live Experience</h3>
                    <ul className="liveCallout_list">
                        <li>Choose how the story unfolds</li>
                        <li>Weekly Q&A Sessions with Matt</li>
                        <li>Writer’s diary by Matt</li>
                        <li>Weekly writing projects</li>
                        <li>Online gallery</li>
                    </ul>
                </div>
            </Container>

            <Container className="liveGrid">
                <div className="liveRegister liveGrid_main">
                    <h3 className="mb-1">Watch the webinar</h3>
                    
                    <div className="inlinePlayerLayout mb-1">
                        <ReactPlayer 
                            url="https://vimeo.com/524979023" 
                            controls={true} 
                            className="inlinePlayer"
                            width="100%"
                            height="100%"
                            config={{
                                vimeo: {
                                    playerOptions:{
                                        title: false,
                                        byline: false,
                                    }
                                }
                            }} />
                    </div>
                    <p>Find out all about this exciting event direct from Matt. You can also download the <a href="/downloads/GoWriteLive-Presentation.pdf">BoomWriter UK Live! presentation slides</a></p>
                </div>
                <div className="LiveRegister_links liveGrid_aside">
                    <h3>Don’t miss out!</h3>
                    <p className="mb-2">If you think your class would like to take part, register your interest and one of our friendly team will be in touch to answer your questions. There's no obligation to take part!</p>
                </div>
            </Container>

            <Container as="section" className="liveHIW">
                <div className="liveHIW_intro">
                    <h1 className="liveHIW_intro_headline">How It Works</h1>
                    <p className="fontWeight-bold">Matt will reveal the first chapter of the book that ends in a choice for the readers…</p>
                </div>
                <div className="liveHIW_details">
                    <ol className="liveHIW_list">
                        <li className="basic one">
                            <p>On Monday, each child logs in to BoomWriter UK, reads the chapter and then votes for what they think should happen next. </p>
                            <p className="fontWeight-bold">Voting will end each Thursday.</p>
                        </li>
                        <li className="two">
                            <p>Matt will write the next chapter based on the winning choice - branching adventure style!</p>
                        </li>
                        <li className="basic three">
                        <p>The next chapter will be live on the following Monday and this will continue for four weeks until the story is complete. </p>
                        </li>
                    </ol>
                    <div className="liveHIW_aside">
                        <div className="liveWriters">
                            <h2>Your writers in the book</h2>
                            <p>We will include two optional writing tasks each week based upon the chapters that pupils can write using the BoomWriter UK app.</p>
                            <p>These will be added to each child’s personalised book and can be done in class, or at home.</p>
                        </div>
                        <div className="liveInfoPanel">
                            <p>BoomWriter UK is a digital writing platform that is super easy for pupils and teachers to use and a great way to boost engagement and collaboration in the writing process.</p>
                        </div>
                    </div>
                </div>
            </Container>

            <Container className="liveCompletePanel">
                <img src={booksImage} className="liveCompletePanel_books" alt="GoWrite Books" />
                <div className="liveCompletePanel_inner">
                    <h3>The Published Book!</h3>
                    <p>When the event is complete, each child will receive a copy of the finished story and all of their creative writing.</p>
                    <p>The book will also include some fantastic digital extras unique to the project!</p>
                </div>
            </Container>
            <Container className="flexProjPanel">
                <div className="flexProjPanel_img"></div>
                <div className="flexProjPanel_inner">
                    <h3>A Flexible Project</h3>
                    <p className="fontWeight-bold">GoWriteLive! has been designed to be completely flexible for busy schools. Jump in to as many of the various activities you want during school time or beyond.</p>
                    <p>It’s a great way to motivate your pupils and help promote reading, writing and art skills.</p>
                </div>
            </Container>
            
            <Container className="">
                <div className="liveExtraSection">
                    <div className="liveExtraSection_col">
                        <div className="liveDiary">
                            <img src={diaryImage} alt="Matt's Video Diary" className="liveDiary_img"/>
                            <div className="liveDiary_desc">
                                <h3>Matt’s Video Diary</h3>
                                <p>Matt will be doing a writer’s video diary each week talking about the process of writing the story, providing pupils with a unique insight into the writing process.</p>
                            </div>
                        </div>
                        <div className="liveFeatures">
                            <div className="liveFeature">
                                <h3>Live Q&A</h3>
                                <p>There will be a live Q&A session on Friday afternoon with Matt each week that schools can login to and children can ask Matt questions about the story or being a writer.</p>
                            </div>
                            <div className="liveFeature">
                                <h3>Share your pictures</h3>
                                <p>We will also be encouraging artwork based upon the story throughout the project. We’ll be providing opportunities to share illustrations and drawings on the BoomWriter UK webpage.</p>
                            </div>
                        </div>
                        <div className="liveCostPanel">
                            <h3>So, what does it cost?</h3>
                            <p className="fontWeight-bold">It will cost just £7.99 per child to take part. Plus you can use any of your existing book packs.</p>
                            <p>Cost includes access to all the live events, writing projects and a personalised copy of the book for every pupil.</p>
                        </div>
                    </div>
                    
                </div>
            </Container>

        </Layout>

    </>
    )
}

export default LivePage